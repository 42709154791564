import { ReactNode, useEffect } from 'react';
import { Icon } from '@iconify-icon/react';
import UpgradeButton from '@/plans/components/UpgradeButton';
import useAccount from '@/account/hooks/useAccount';
import Button from '@/storychief/components/Button';
import StoryChief from '@/storychief';
import analyticsTrack from '@/storychief/utils/analyticsTrack';

type EntitlementTeaserPanelProps = {
  entitlement: string;
  children?: ReactNode;
  title: string;
  showButton: boolean;
  onUpgrade: () => void;
};

function EntitlementTeaserPanel({
  entitlement,
  children = null,
  title = null,
  showButton = true,
  onUpgrade = () => {},
}: EntitlementTeaserPanelProps) {
  const account = useAccount();

  function handleOnContactUs() {
    StoryChief.startIntercomMessage();
  }

  function renderButton() {
    if (account?.subscription?.can?.update) {
      return <UpgradeButton entitlement={entitlement} onUpgrade={onUpgrade} />;
    }

    return (
      <Button variant="secondary" size="sm" onClick={handleOnContactUs}>
        Contact us
      </Button>
    );
  }

  useEffect(() => {
    analyticsTrack('Feature Teaser Viewed', { entitlement, variant: 'popover' });
  }, []);

  return (
    <div className="entitlement-teaser-panel">
      <strong className="entitlement-teaser-panel__title flex items-center">
        <Icon icon="clarity:circle-arrow-line" className="mr-1 text-2xl text-regular-blue" inline />
        {title || 'Upgrade to use this feature'}
      </strong>
      <div className="entitlement-teaser-panel__description">
        {children || 'This feature is not a part of your plan.'}
      </div>

      {showButton && <div className="entitlement-teaser-panel__footer">{renderButton()}</div>}
    </div>
  );
}

export default EntitlementTeaserPanel;
